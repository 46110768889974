@import "no-style-please";
.tab { display: flex; }
.tab button { background-color: inherit; border: none; outline: none; cursor: pointer; padding: 14px 16px; transition: 0.3s; flex: 1; text-align: center; border-bottom: 3px solid #ccc; font-family: 'Courier New', Courier, monospace; font-size: inherit}
.tab button.active { border-bottom: 3px solid #000; }
.tabcontent { display: none; padding: 6px 12px; border-top: none; }
.tabcontent pre { font-family: 'Courier New', Courier, monospace; }
.accordion { max-width: 400px; margin: 20px auto; }
.accordion-item { border: 1px solid #000; margin-bottom: 5px; }
.accordion-item-header { background-color: #000; padding: 10px; cursor: pointer; color: white; font-family: 'Courier New', Courier, monospace; }
.accordion-item-header::after { content: '\2212'; color: white; font-weight: bold; float: right; margin-left: 5px; }
.accordion-item-header.collapsed::after { content: '\002B'; }
.accordion-item-body { height: 0; overflow: hidden; }
.accordion-item-body pre { margin: 0; padding: 8px 16px; font-family: 'Courier New', Courier, monospace; }
.png4-3 { width: 100%; height: auto; max-width: 100%; aspect-ratio: 4 / 3; }